import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer_v2 extends Component {
  componentDidMount() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "Footer logo";

    return (
      <footer className="footer-area footer-area-2 bg-gray">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_about text-center">
                  <Link to="/">
                    <img
                      style={{
                        width: "80px",
                        height: "80px",
                        border: "2px solid white",
                        borderRadius: "100px",
                      }}
                      src={publicUrl + "assets/img/logo3.png"}
                      alt="Devops training online courses"
                    />
                  </Link>
                  <div className="details">
                    <p>
                      Aarav EdTech is dedicated to empowering our students with
                      the essential skills and self-assurance required to thrive
                      in the ever-evolving tech arena.
                    </p>
                    <ul className="social-media">
                      <li>
                        <a href="https://www.facebook.com/aaravedtech">
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/aarav_pvt">
                          <i className="fa fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/aaravedtech/">
                          <i className="fa fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_contact">
                  <h4 className="widget-title">Contact Us</h4>
                  <ul className="details">
                    <li>
                      <i className="fa fa-map-marker" />
                      NO.75/7, WEST OF CHORD ROAD, NEXT TO BANGLORE ONE,
                      R-BLOCK, RAJAJINAGAR, BENGALURU-560010
                    </li>
                    <li>
                      <i className="fa fa-envelope" /> career@aaravedtech.com
                    </li>
                    <li>
                      <i className="fa fa-phone" />
                      +91 8073057369
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_blog_list">
                  <h4 className="widget-title">Courses</h4>

                  <ul className="go-top">
                    <li>
                      <Link to="/course">Full Stack Java Developer</Link>
                    </li>
                    <li>
                      <Link to="/course">Full Stack Python Developer</Link>
                    </li>
                    <li>
                      <Link to="/course">Frontend Developer</Link>
                    </li>
                    <li>
                      <Link to="/course">Backend Developer</Link>
                    </li>
                    <li>
                      <Link to="/course">Mobile App Developer</Link>
                    </li>
                    <li>
                      <Link to="/course">Devops Developer</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_nav_menu">
                  <h4 className="widget-title">Company</h4>
                  <ul className="go-top">
                    <li>
                      <Link to="/">Careers</Link>
                    </li>
                    <li>
                      <a href="https://drive.google.com/file/d/1szsx1Cq7_-P0W8Rup7hpOpMlsHDLEmru/view?usp=sharing">
                        Priyacy Policy
                      </a>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-5 align-self-center">
                <p>Copyright 2023 Aaraved Tech. All rights reserved.</p>
              </div>
              <div className="col-md-7 text-md-right align-self-center mt-md-0 mt-2">
                <div className="widget_nav_menu">
                  <ul className="go-top">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/pricing">Our Courses</Link>
                    </li>
                    <li>
                      <Link to="/blog">blog</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer_v2;
