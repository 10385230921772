import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class IntroV2 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return (
      <div className="intro-area intro-area--top">
        <div className="container">
          <div
            className="intro-area-inner-2"
            style={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
            }}
          >
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center">
                  <h6 className="sub-title double-line">FEATURES</h6>
                  <h2 className="title">Available Courses</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="single-intro-inner style-thumb text-center">
                  <div className="thumb">
                    <img
                      src={publicUrl + "assets/img/intro/4.png"}
                      alt="Web development certification courses"
                    />
                  </div>
                  <div className="details">
                    <h5>Masters In Web Development</h5>
                    <p>
                      Build your programming knowledge in front-end, back-end,
                      or full-stack development with our industry-leading
                      courses.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="single-intro-inner style-thumb text-center">
                  <div className="thumb">
                    <img
                      src={publicUrl + "assets/img/intro/5.png"}
                      alt="Full stack developer course"
                    />
                  </div>
                  <div className="details">
                    <h5>Master In App Development</h5>
                    <p>
                      Advance your education and career in app development.
                      Explore our comprehensive courses to begin your journey as
                      app developer.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="single-intro-inner style-thumb text-center">
                  <div className="thumb">
                    <img
                      src={publicUrl + "assets/img/intro/6.png"}
                      alt="Devops training online courses"
                    />
                  </div>
                  <div className="details">
                    <h5>Master In Devops</h5>
                    <p>
                      Advance your knowledge of DevOps methodologies & tools
                      with our comprehensive courses, designed to streamline
                      your devOps processes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="intro-footer bg-base">
              <div className="row">
                <div className="col-md-4">
                  <div className="single-list-inner">
                    <div className="media">
                      <div className="media-left">
                        <img
                          src={publicUrl + "assets/img/icon/19.png"}
                          alt="img"
                        />
                      </div>
                      <div className="media-body align-self-center">
                        <h5>Postgraduate</h5>
                        <p>Eligible</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single-list-inner">
                    <div className="media">
                      <div className="media-left">
                        <img
                          src={publicUrl + "assets/img/icon/20.png"}
                          alt="img"
                        />
                      </div>
                      <div className="media-body align-self-center">
                        <h5>Graduate</h5>
                        <p>Eligible</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="single-list-inner">
                    <div className="media">
                      <div className="media-left">
                        <img
                          src={publicUrl + "assets/img/icon/21.png"}
                          alt="img"
                        />
                      </div>
                      <div className="media-body align-self-center">
                        <h5>Engineering</h5>
                        <p>Eligible</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
        }
}

export default IntroV2