import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class CoursePage extends Component {
  state = {
    courseData: [],
  };

  componentDidMount() {
    const apiUrl = "https://course-9rh3.onrender.com/course";

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ courseData: data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="blog-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row">
            {this.state.courseData.map((data, i) => (
              <div className="col-lg-4 col-md-6" key={i}>
                <div className="single-course-inner style-two">
                  <div className="emt-thumb-icon">
                    <img src={publicUrl + "assets/img/icon/6.png"} alt="img" />
                  </div>
                  <div className="thumb">
                    <img
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        height: "230px",
                        width: "500px",
                      }}
                      src={data.img}
                      alt="img"
                    />
                  </div>
                  <div className="details">
                    <div className="emt-course-meta border-0">
                      <div className="row">
                        <div className="col-10">
                          <h6>
                            <Link to={`/course-details/${data._id}`}>
                              {data.course_name}
                            </Link>
                          </h6>
                        </div>
                        <div className="col-2 text-right">
                          <Link
                            className="arrow-right"
                            to={`/course-details/${data._id}`}
                          >
                            <img
                              src={publicUrl + "assets/img/icon/5.png"}
                              alt="img"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* <div className="col-lg-4 order-lg-1 col-12">
              <div className="td-sidebar mt-5 mt-lg-0">
                <div className="widget widget_search_course">
                  <h4 className="widget-title">Search</h4>
                  <form className="search-form single-input-inner">
                    <input type="text" placeholder="Search here" />
                    <button className="btn btn-base w-100 mt-3" type="submit">
                      <i className="fa fa-search" /> SEARCH
                    </button>
                  </form>
                </div>
                <div className="widget widget_catagory">
                  <h4 className="widget-title">Catagory</h4>
                  <ul className="catagory-items go-top">
                    <li>
                      
                      Web Devlopment
                      <i className="fa fa-caret-right" />
                     
                    </li>
                    <li>
                     
                      Mobile App Development <i className="fa fa-caret-right" />
                  
                    </li>
                    <li>
                     
                      Devops Devlopment <i className="fa fa-caret-right" />
                  
                    </li>
                    <li>
                     
                      Languages <i className="fa fa-caret-right" />
                  
                    </li>
                  </ul>
                </div>
                <div className="widget widget_checkbox_list">
                  <h4 className="widget-title">Price</h4>
                  <label className="single-checkbox">
                    <input type="checkbox" defaultChecked="checked" />
                    <span className="checkmark" />
                    7000
                  </label>
                  <label className="single-checkbox">
                    <input type="checkbox" />
                    <span className="checkmark" />
                    25000
                  </label>
                  <label className="single-checkbox">
                    <input type="checkbox" />
                    <span className="checkmark" />
                    30000
                  </label>
                </div>
              
                <div className="widget widget_tags mb-0">
                  <h4 className="widget-title">Tags</h4>
                  <div className="tagcloud go-top">
                    <Link to="blog">HTML</Link>
                    <Link to="blog">CSS</Link>
                    <Link to="blog">JavaScript</Link>
                    <Link to="blog">Java</Link>
                    <Link to="blog">Python</Link>
                    <Link to="blog">SpringBoot</Link>
                    <Link to="blog">Django</Link>
                    <Link to="blog">SQL</Link>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default CoursePage