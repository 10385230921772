import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class TestimonialV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlaying: true,
    };
  }

  handleVideoClick = () => {
    this.setState({ isPlaying: !this.state.isPlaying });
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const { isPlaying } = this.state;

    return (
      <div
        className="testimonial-area pd-top-110 pd-bottom-120"
        style={{
          backgroundImage:
            "url(" + publicUrl + "assets/img/testimonial/bg.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "500px",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-11">
              <div className="section-title text-center">
                <h6 className="sub-title double-line">Students Testimonials</h6>
                <h2 className="title">Hear From Our Alumni</h2>
              </div>
            </div>
          </div>
          <div className="testimonial-slider-2 owl-carousel">
            <div className="item">
              <div className="single-testimonial-inner">
                {/* <span className="testimonial-quote">
                  <i className="fa fa-quote-right" />
                </span> */}
                <div
                  className="video-container"
                  style={{
                    height: "250px",
                    width: "650px",
                    position: "relative",
                  }}
                  onClick={this.handleVideoClick}
                >
                  {isPlaying ? (
                    <iframe
                      width="50%"
                      height="70%"
                      src="https://www.youtube.com/embed/Cn5MSnIdsiU?si=UMn5fWNUL05nB7ZH"
                      title="YouTube Video"
                      frameBorder="0"
                      allowFullScreen
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="single-testimonial-inner">
                {/* <span className="testimonial-quote">
                  <i className="fa fa-quote-right" />
                </span> */}
                <div
                  className="video-container"
                  style={{
                    height: "250px",
                    width: "650px",
                    position: "relative",
                  }}
                  onClick={this.handleVideoClick}
                >
                  {isPlaying ? (
                    <iframe
                      width="50%"
                      height="70%"
                      src="https://www.youtube.com/embed/gNG2HEVHTMs?si=e-DMcgarQQwX8iWj"
                      title="YouTube Video"
                      frameBorder="0"
                      allowFullScreen
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="item">
              <div className="single-testimonial-inner">
                {/* <span className="testimonial-quote">
                  <i className="fa fa-quote-right" />
                </span> */}
                <div
                  className="video-container"
                  style={{
                    height: "250px",
                    width: "650px",
                    position: "relative",
                  }}
                  onClick={this.handleVideoClick}
                >
                  {isPlaying ? (
                    <iframe
                      width="50%"
                      height="70%"
                      src="https://www.youtube.com/embed/fdsWKR0C-Pw?si=096yi_NRnAHFLssJ"
                      title="YouTube Video"
                      frameBorder="0"
                      allowFullScreen
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestimonialV2;
