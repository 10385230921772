import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
// import { withRouter } from "react-router-dom";

class Footer_v1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        first_name: "",
        email: "",
      },
      error: "", // Add error state to store any form submission errors
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      form: { ...this.state.form, [name]: value },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.subscribeUser(this.state.form);
  };

  subscribeUser = (user) => {
    const { email, first_name } = user;
    if (email || first_name) {
      axios
        .post("https://aarav-ed-tech.onrender.com", user) // POST request to the specified URL
        .then((res) => {
          alert(res.data.message);
          this.props.history.push("/");
        })
        .catch((err) => {
          console.error(err);
          this.setState({ error: "An error occurred while subscribing." });
        });
    } else {
      this.setState({ error: "Please provide either email or first_name" });
    }
  };
  componentDidMount() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <footer className="footer-area bg-gray">
        {/* <div className="footer-subscribe">
          <div className="container">
            <form
              className="footer-subscribe-inner"
              onSubmit={this.handleSubmit}
            >
              <div className="row">
                <div className="col-lg-5">
                  <div className="single-input-inner style-border-bottom">
                    <input
                      type="text"
                      placeholder="Your Full Name"
                      name="first_name"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="single-input-inner style-border-bottom">
                    <input
                      type="text"
                      placeholder="Your Email Address"
                      name="email"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-2">
                  <Link>
                    <button className="btn btn-base" to="" type="submit">
                      Subscribe
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div> */}
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_contact">
                  <h4 className="widget-title">Contact Us</h4>
                  <ul className="details">
                    <li>
                      <i className="fa fa-map-marker" /> NO.75/7, WEST OF CHORD
                      ROAD, NEXT TO BANGLORE ONE, R-BLOCK, RAJAJINAGAR,
                    </li>
                    <li>
                      <i className="fa fa-envelope" /> career@aaravedtech.com
                    </li>
                    <li>
                      <i className="fa fa-phone" /> +91 8073057369
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-6">
                <div className="widget widget_nav_menu">
                  <h4 className="widget-title">Courses</h4>
                  <ul className="go-top">
                    <li>
                      <Link to="/blog">Full Stack Java Developer</Link>
                    </li>
                    <li>
                      <Link to="/blog">Full Stack Python Developer </Link>
                    </li>
                    <li>
                      <Link to="/blog">Frontend Developer</Link>
                    </li>
                    <li>
                      <Link to="/blog">Backend Developer</Link>
                    </li>
                    <li>
                      <Link to="/blog">Mobile App Developer</Link>
                    </li>
                    <li>
                      <Link to="/blog">Devops Developer</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_nav_menu">
                  <h4 className="widget-title">Courses</h4>
                  <ul className="go-top">
                    <li>
                      <Link to="/blog">Full Stack Java Developer</Link>
                    </li>
                    <li>
                      <Link to="/blog">Full Stack Python Developer </Link>
                    </li>
                    <li>
                      <Link to="/blog">Frontend Developer</Link>
                    </li>
                    {/* <li>
                      <Link to="/blog">Backend Developer</Link>
                    </li>
                    <li>
                      <Link to="/blog">Mobile App Developer</Link>
                    </li>
                    <li>
                      <Link to="/blog">Devops Developer</Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_nav_menu">
                  <h4 className="widget-title">Courses</h4>
                  <ul className="go-top">
                    {/* <li>
                      <Link to="/blog">Full Stack Java Developer</Link>
                    </li>
                    <li>
                      <Link to="/blog">Full Stack Python Developer </Link>
                    </li>
                    <li>
                      <Link to="/blog">Frontend Developer</Link>
                    </li> */}
                    <li>
                      <Link to="/blog">Backend Developer</Link>
                    </li>
                    <li>
                      <Link to="/blog">Mobile App Developer</Link>
                    </li>
                    <li>
                      <Link to="/blog">Devops Developer</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 go-top">
                <div className="widget widget_blog_list">
                  <h4 className="widget-title">Company</h4>
                  <ul className="go-top">
                    <li>
                      <Link to="/">Careers</Link>
                    </li>
                    <li>
                      <a href="https://drive.google.com/file/d/1szsx1Cq7_-P0W8Rup7hpOpMlsHDLEmru/view?usp=sharing">
                        Priyacy Policy
                      </a>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_contact">
                  {/* <h4 className="widget-title">Company</h4>
                  <ul className="details">
                    <li>
                      <i className="fa fa-twitter" />
                      <Link to="/">Careers</Link>
                    </li>
                    <li>
                      <i className="fa fa-twitter" />

                      <Link to="/">Priyacy Policy</Link>
                    </li>
                    <li>
                      <i className="fa fa-twitter" />

                      <Link to="/">About Us</Link>
                    </li>
                    <li>
                      <i className="fa fa-twitter" />

                      <Link to="/">Contact Us</Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom" style={{ padding: "0px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 align-self-center">
                <Link to="/">
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      // border: "2px solid white",
                    }}
                    src={publicUrl + "assets/img/loo.png"}
                    alt="img"
                  />
                </Link>
              </div>
              <div className="col-lg-4  col-md-6 order-lg-12 text-md-right align-self-center">
                <ul className="social-media mt-md-0 mt-3">
                  <li>
                    <a
                      className="facebook"
                      href="https://www.facebook.com/aaravedtech"
                    >
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a className="twitter" href="https://twitter.com/aarav_pvt">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="instagram"
                      href="https://www.instagram.com/aaravedtech"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      className="Linkedin"
                      href="https://www.linkedin.com/in/aarav-ed-tech-b16b38293/"
                    >
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="pinterest"
                      href="https://www.pinterest.com/solverwp/"
                    >
                      <i className="fa fa-pinterest" />
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="col-lg-4 order-lg-8 text-lg-center align-self-center mt-lg-0 mt-3">
                <p>© Copyright 2023 Aaraved Tech.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}


export default Footer_v1