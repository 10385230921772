import React from "react";
import Carousel from "react-elastic-carousel";

class Client extends React.Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    const logos = [
      // publicUrl + "assets/img/institute/1.jpeg",
      publicUrl + "assets/img/institute/2.jpeg",
      publicUrl + "assets/img/institute/3.jpeg",
      publicUrl + "assets/img/institute/4.jpeg",
      publicUrl + "assets/img/institute/5.jpeg",
      publicUrl + "assets/img/institute/6.jpeg",
      publicUrl + "assets/img/institute/7.jpeg",
      publicUrl + "assets/img/institute/8.jpeg",
      publicUrl + "assets/img/institute/10.jpeg",
      publicUrl + "assets/img/institute/11.jpeg",
      publicUrl + "assets/img/institute/12.jpeg",
      publicUrl + "assets/img/institute/13.jpeg",
      publicUrl + "assets/img/institute/15.jpeg",
      publicUrl + "assets/img/institute/16.jpeg",
      publicUrl + "assets/img/institute/2.jpeg",
      publicUrl + "assets/img/institute/3.jpeg",
      publicUrl + "assets/img/institute/4.jpeg",
      publicUrl + "assets/img/institute/5.jpeg",
      publicUrl + "assets/img/institute/6.jpeg",
      publicUrl + "assets/img/institute/7.jpeg",
      publicUrl + "assets/img/institute/8.jpeg",
      publicUrl + "assets/img/institute/10.jpeg",
      publicUrl + "assets/img/institute/11.jpeg",
      publicUrl + "assets/img/institute/12.jpeg",
      publicUrl + "assets/img/institute/13.jpeg",
      publicUrl + "assets/img/institute/15.jpeg",
      publicUrl + "assets/img/institute/16.jpeg",
      publicUrl + "assets/img/institute/2.jpeg",
      publicUrl + "assets/img/institute/3.jpeg",
      publicUrl + "assets/img/institute/4.jpeg",
      publicUrl + "assets/img/institute/5.jpeg",
      publicUrl + "assets/img/institute/6.jpeg",
      publicUrl + "assets/img/institute/7.jpeg",
      publicUrl + "assets/img/institute/8.jpeg",
      publicUrl + "assets/img/institute/10.jpeg",
      publicUrl + "assets/img/institute/11.jpeg",
      publicUrl + "assets/img/institute/12.jpeg",
      publicUrl + "assets/img/institute/13.jpeg",
      publicUrl + "assets/img/institute/15.jpeg",
      publicUrl + "assets/img/institute/16.jpeg",
    ];

    const breakPoints = [
      { width: 1, itemsToShow: 8 },
      { width: 50, itemsToShow: 8 },
      { width: 768, itemsToShow: 8 },
      { maxWidth: 1800, itemsToShow: 8 },
    ];

    return (
      <>
        <div className="col-lg-6">
          <div
            className="section-title text-center"
            style={{
              textAlign: "center",
              width: "100vw",
            }}
          >
            <h6 className="sub-title double-line">FEATURES</h6>
            <h2 className="title">Meet Our Hiring Partners</h2>
          </div>
        </div>

        <div
          className="client-area bg-base pd-top-100 pd-bottom-100"
          style={{
            backgroundImage: `url(${publicUrl}assets/img/institute/bg.png)`,
            padding: "35px",
          }}
        >
          <div className="container">
            <div
              style={{
                width: "120vw",

                marginLeft: "-270px",
                // border: "2px solid red",
              }}
            >
              <div
                className="col-lg-12"
                style={{
                  // width: "130vw",
                  height: "100px",
                }}
              >
                <Carousel
                  breakPoints={breakPoints}
                  enableAutoPlay={true}
                  autoPlaySpeed={1000}
                  isRTL={true}
                >
                  {logos.map((logo, index) => (
                    <div
                      key={index}
                      className="item"
                      style={{
                        border: "2px solid white",
                        height: "100px",
                        background: "white",
                        borderRadius: "100px",
                        width: "100px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;",
                        borderRadius: "10px",
                      }}
                    >
                      <img src={logo} alt={`Logo ${index + 1}`} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Client;
