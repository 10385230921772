import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV2 from "./components/home-v2";
import Course from "./components/course";
import About from "./components/about";
import InstructorDetails from "./components/instructor-details";
import Pricing from "./components/pricing";
import SignIn from "./components/sign-in";
import SignUp from "./components/sign-up";
import Contact from "./components/contact";
import Blog from "./components/blog";
import AOS from "aos";
import CourseDetails from "./components/section-components/course-details";
import Kommunicate from "@kommunicate/kommunicate-chatbot-plugin";
import ReactGA from "react-ga";
const TRACKING_ID = "G-7GDR1XMSL4"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

ReactGA.initialize(TRACKING_ID);

class Root extends Component {
  // componentDidMount() {
  //   AOS.init();

  //   Kommunicate.init("kommunicate-support", {
  //     automaticChatOpenOnNavigation: true,
  //     popupWidget: true,
  //   });
  // }

  render() {
    ReactGA.pageview(window.location.pathname + window.location.search);
    const whatsappIconStyle = {
      position: "fixed",
      width: "60px",
      height: "60px",
      bottom: "120px",
      right: "25px",
      backgroundColor: "#25d366",
      color: "#FFF",
      borderRadius: "50px",
      textAlign: "center",
      fontSize: "30px",
      boxShadow: "2px 2px 3px #999",
      zIndex: "100",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      textDecoration: "none",
    };

    return (
      <Router basename="/">
        <div>
          <Switch>
            <Route exact path="/" component={HomeV2} />
            <Route path="/course" component={Course} />
            <Route path="/about" component={About} />
            <Route path="/course-details/:id" component={CourseDetails} />
            <Route path="/instructor-details" component={InstructorDetails} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/sign-in" component={SignIn} />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/contact" component={Contact} />
            <Route path="/blog" component={Blog} />
          </Switch>
          {/* WhatsApp */}
          <a
            href="https://wa.me/+918073057369"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-whatsapp" style={whatsappIconStyle}></i>
          </a>
          <br />
        </div>
      </Router>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));


