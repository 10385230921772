import React from "react";
import Navbar from "./global-components/navbar-v2";
import PageHeader from "./global-components/page-header";
import SignUp from "./section-components/sign-up";
import Footer from "./global-components/footer";

const SingUpPage = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Contact Us" />
      <SignUp />
      <Footer />
    </div>
  );
};

export default SingUpPage;
