import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TestimonialV4 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return (
      <div
        className="pricing-area pd-top-280 pd-bottom-120 text-center"
        style={{
          backgroundImage: "url(" + publicUrl + "assets/img/bg/pricing-bg.png)",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-11">
              <div className="section-title text-center">
                <h6 className="sub-title double-line">Students Testimonials</h6>
                <h2 className="title">What our Students say </h2>
              </div>
            </div>
          </div>
          <div className="testimonial-slider-2 owl-carousel">
            <div className="item">
              <div className="single-testimonial-inner">
                <span className="testimonial-quote">
                  <i className="fa fa-quote-right" />
                </span>
                <p>
                  Arrav Ed Tech's online IT development classes were a
                  game-changer for my career transition. Their flexible courses,
                  passionate instructors, and practical projects prepared me for
                  the tech industry's challenges. I owe my success to Arrav Ed
                  Tech's exceptional education
                </p>
                <div className="media testimonial-author">
                  {/* <div className="media-left">
                    <img
                      src={publicUrl + "assets/img/testimonial/d.jpeg"}
                      alt="img"
                    />
                  </div> */}
                  <div className="media-body align-self-center">
                    <h6>Megha K P</h6>
                    <p>Android Developer</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="single-testimonial-inner">
                <span className="testimonial-quote">
                  <i className="fa fa-quote-right" />
                </span>
                <p>
                  Aarav Ed Tech's IT development programs transformed my
                  professional trajectory. With flexible curriculum, dedicated
                  mentors, and practical projects, I gained the skills to
                  navigate the challenges of the tech industry. Aarav Ed Tech's
                  outstanding education is the cornerstone of my success.
                  {/* Arrav Ed Tech transformed my career. Their online IT
                  development classes, led by expert and supportive instructors,
                  prepared me for success. Now, as a software developer at Tech
                  Innovators Inc., I credit much of my achievement to Arrav Ed
                  Tech's top-notch education */}
                </p>
                <div className="media testimonial-author">
                  {/* <div className="media-left">
                    <img
                      src={publicUrl + "assets/img/testimonial/d.jpeg"}
                      alt="img"
                    />
                  </div> */}
                  <div className="media-body align-self-center">
                    <h6>Sunil Kumar</h6>
                    <p>Frontend Developer</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="single-testimonial-inner">
                <span className="testimonial-quote">
                  <i className="fa fa-quote-right" />
                </span>
                <p>
                  Aarav Ed Tech played a pivotal role in my career shift. Their
                  adaptable courses, passionate educators, and practical
                  projects provided the skills needed for the dynamic tech
                  landscape. My achievements are a testament to Aarav Ed Tech's
                  excellent educational approach.
                  {/* Arrav Ed Tech transformed my career. Their online IT
                  development classes, led by expert and supportive instructors,
                  prepared me for success. Now, as a software developer at Tech
                  Innovators Inc., I credit much of my achievement to Arrav Ed
                  Tech's top-notch education */}
                </p>
                <div className="media testimonial-author">
                  {/* <div className="media-left">
                    <img
                      src={publicUrl + "assets/img/testimonial/d.jpeg"}
                      alt="img"
                    />
                  </div> */}
                  <div className="media-body align-self-center">
                    <h6>Rina roy</h6>
                    <p>Software Developer</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
        }
}

export default TestimonialV4