import React, { Component } from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

class TeamV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="team-area pd-top-120 pd-bottom-90 go-top">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7">
              <div className="section-title text-center">
                <h6 className="sub-title double-line">Meet Our Team</h6>
                <h2 className="title">Our Creative Team</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center testimonial-slider-2 owl-carousel">
            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner">
                {/* <div className="thumb">
                  <img src={publicUrl + "assets/img/team/b.jpg"} alt="img" />
                  <div className="social-wrap">
                    <div className="social-wrap-inner">
                   
                    </div>
                  </div>
                </div> */}
                <div
                  className="details"
                  style={{
                    width: "330px",

                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <span>
                    <h4 style={{ textAlign: "center" }}>
                      {" "}
                      <Link to="/about">Lokesh</Link>
                    </h4>
                    <h6 style={{ textAlign: "center" }}>
                      ( &nbsp;Full Stack Developer &nbsp;)
                    </h6>
                  </span>

                  <div>
                    Lokesh is a dynamic IT specialist with over 7 years of
                    experience, skilled in software development. His proficiency
                    in various programming languages make him a standout in the
                    industry. Lokesh's commitment to staying current in the
                    ever-evolving IT landscape makes him a valuable resource for
                    innovative solutions.
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner">
                {/* <div className="thumb">
                  <img src={publicUrl + "assets/img/team/a.jpg"} alt="img" />
                  <div className="social-wrap">
                    <div className="social-wrap-inner">
                
                    </div>
                  </div>
                </div> */}
                <div
                  className="details"
                  style={{
                    width: "330px",

                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <h4 style={{ textAlign: "center" }}>
                    <Link to="/about">Sushma</Link>
                  </h4>
                  <h6 style={{ textAlign: "center" }}>
                    ( &nbsp;Frontend Developer &nbsp;)
                  </h6>
                  <span>
                    Sushma is a dedicated IT professional with over 5 years of
                    experience in software engineering, project management.
                    Known for his innovative solutions and commitment to
                    excellence, Sachin excels in the ever-evolving IT landscape.
                    His continuous learning make him a valuable asset in driving
                    successful projects .
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-team-inner">
                {/* <div className="thumb">
                  <img src={publicUrl + "assets/img/team/b.jpeg"} alt="img" />
                  <div className="social-wrap">
                    <div className="social-wrap-inner">
                 
                    </div>
                  </div>
                </div> */}
                <div
                  className="details"
                  style={{
                    width: "330px",

                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <h4 style={{ textAlign: "center" }}>
                    <Link to="/about">Suraj Jain</Link>
                  </h4>
                  <h6 style={{ textAlign: "center" }}>
                    {" "}
                    ( &nbsp;Full Stack Developer &nbsp;)
                  </h6>

                  <span>
                    Suraj Jain is a seasoned IT trainer with 15 years of
                    industry experience. Specializing in Java and Python, he's
                    known for delivering top-notch training to individuals and
                    organizations. His deep knowledge of programming, web
                    development, and software architecture makes him a trusted
                    resource in the tech world.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamV3;
