import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class Sidebar extends Component {
  render() {
   let anchor = '#'
   let imagealt = 'image'
   let publicUrl = process.env.PUBLIC_URL+'/'
    return (
      <div className="col-lg-4 col-12">
        <div className="td-sidebar">
          {/* <div className="widget widget_search">
            <form className="search-form">
              <div className="form-group">
                <input type="text" placeholder="Search" />
              </div>
              <button className="submit-btn" type="submit">
                <i className="fa fa-search" />
              </button>
            </form>
          </div>
          <div className="widget widget_catagory">
            <h4 className="widget-title">Catagory</h4>
            <ul className="catagory-items go-top">
              <li>
                <Link to="/blog">
                  Web Development
                  <i className="fa fa-caret-right" />
                </Link>
              </li>
              <li>
                <Link to="/blog">
                  Mobile App Development
                  <i className="fa fa-caret-right" />
                </Link>
              </li>
              <li>
                <Link to="/blog">
                  Devops Devlopment
                  <i className="fa fa-caret-right" />
                </Link>
              </li>
              <li>
                <Link to="/blog">
                  Languages <i className="fa fa-caret-right" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="widget widget-recent-post">
            <h4 className="widget-title">Recent News</h4>
            <ul>
              <li>
                <div className="media">
                  <div className="media-left">
                    <img
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                      src={publicUrl + "assets/img/widget/a.jpeg"}
                      alt="blog"
                    />
                  </div>
                  <div className="media-body align-self-center">
                    <h5 className="title">
                      <Link to="/blog">
                        The Future of IT Education: Online Classes Lead the Way
                        in Skill Development
                      </Link>
                    </h5>
                    <div className="post-info">
                      <i className="fa fa-calendar" />
                      <span>15 October</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="media">
                  <div className="media-left">
                    <img
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                      src={publicUrl + "assets/img/widget/b.jpeg"}
                      alt="blog"
                    />
                  </div>
                  <div className="media-body align-self-center">
                    <h5 className="title">
                      <Link to="/blog">
                        Tech Giants Partner with Online IT Schools to Support
                        Skill Development
                      </Link>
                    </h5>
                    <div className="post-info">
                      <i className="fa fa-calendar" />
                      <span>15 October</span>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="media">
                  <div className="media-left">
                    <img
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "50px",
                      }}
                      src={publicUrl + "assets/img/widget/c.jpeg"}
                      alt="blog"
                    />
                  </div>
                  <div className="media-body align-self-center">
                    <h5 className="title">
                      <Link to="/blog">
                        Government Initiatives Promote Access to Quality Online
                        IT Education for All
                      </Link>
                    </h5>
                    <div className="post-info">
                      <i className="fa fa-calendar" />
                      <span>15 October</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}
          {/* <div className="widget widget_price">
            <h4 className="widget-title">Price</h4>
            <label className="single-checkbox">
              <input type="checkbox" defaultChecked="checked" />
              <span className="checkmark" />
              Free Courses
            </label>
            <label className="single-checkbox">
              <input type="checkbox" defaultChecked="checked" />
              <span className="checkmark" />
              Paid Courses
            </label>
            <label className="single-checkbox">
              <input type="checkbox" defaultChecked="checked" />
              <span className="checkmark" />
              Only Subscription
            </label>
          </div> */}
          {/* <div className="widget widget_level">
            <h4 className="widget-title">Price</h4>
            <label className="single-checkbox">
              <input type="checkbox" defaultChecked="checked" />
              <span className="checkmark" />
              7000
            </label>
            <label className="single-checkbox">
              <input type="checkbox" defaultChecked="checked" />
              <span className="checkmark" />
              25000
            </label>
            <label className="single-checkbox">
              <input type="checkbox" defaultChecked="checked" />
              <span className="checkmark" />
              30000
            </label>
          </div> */}
          <div className="widget widget_tags mb-0">
            <h4 className="widget-title">Tags</h4>
            <div className="tagcloud go-top">
              <Link>HTML</Link>
              <Link>CSS</Link>
              <Link>JAVASCRIPT</Link>
              <Link>REACT.JS</Link>
              <Link>JAVA</Link>
              <Link>PYTHON</Link>
              <Link>SPRING BOOT</Link>
              <Link>DJANGO</Link>
              <Link>SQL</Link>
              <Link>NODE.JS</Link>
              <Link>EXPRESS.JS</Link>
              <Link>MONGODB</Link>
              <Link>REACT.NATIVE</Link>
              <Link>FLUTTER</Link>
              <Link>DEVOPS</Link>
              <Link>ENGLISH</Link>
              <Link>HINDI</Link>
              <Link>KANNADA</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
