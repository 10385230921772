import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Pricing extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return (
      <div className="pricing-area pd-top-120 pd-bottom-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing-inner-wrap">
                <div className="single-pricing-inner">
                  <h6 className="title" style={{ textAlign: "center" }}>
                    Web devolopment
                  </h6>
                  <div className="price-area">
                    {/* <span>$59 </span>
			              / month */}
                  </div>

                  <ul className="pricing-list">
                    <li>
                      <i className="fa fa-check" />
                      HTML
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      CSS
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      JAVASCRIPT
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      REACT
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      JAVA
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      PYTHON
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      SPRINGBOOT
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      DJANGO
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      SQL/POSTGRESSQL
                    </li>
                  </ul>
                  <Link className="btn btn-base btn-radius w-100" to="/course">
                    GET STARTED
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing-inner-wrap">
                <div className="single-pricing-inner">
                  <h6 className="title" style={{ textAlign: "center" }}>
                    Mobile App Development
                  </h6>
                  <div className="price-area">
                    {/* <span>$99 </span>
			              / month */}
                  </div>
                  <ul className="pricing-list">
                    <li>
                      <i className="fa fa-check" />
                      BASICS UI DESIGN
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      ADVANCED UI DESIGN
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      REACT.NATIVE
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      FLUTTER
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      MULTIMEDIA IN ANDROID
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      TESTING & DEBUGGING
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      ANDROID SECURE CODING.
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      APP BUILD & RELEASE
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      SQL/POSTGRESSQL
                    </li>
                  </ul>
                  <Link className="btn btn-base btn-radius w-100" to="/course">
                    GET STARTED
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing-inner-wrap">
                <div className="single-pricing-inner">
                  <h6 className="title" style={{ textAlign: "center" }}>
                    Devops Developmentt
                  </h6>
                  <div className="price-area">
                    {/* <span>$211 </span>
			              / month */}
                  </div>
                  <ul className="pricing-list">
                    <li>
                      <i className="fa fa-check" />
                      CI/CD (JENKINS)
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      PYTHON
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      AWS ARCHITECT
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      GOOGLE ARCHITECTURE
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      AZURE ARCHITECTURE
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      DOMAIN DRIVEN DESIGN
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      MICROSERVICES
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      SOA
                    </li>
                    <li>
                      <i className="fa fa-check" />
                      CLOUD STORAGE
                    </li>
                  </ul>
                  <Link className="btn btn-base btn-radius w-100" to="/course">
                    GET STARTED
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

        }
}

export default Pricing