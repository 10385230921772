import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class NavbarV2 extends Component {
  componentDidMount() {
    const $ = window.$;

    $("body").removeClass("home-3");
  }

  scrollToContact = () => {
    window.scrollTo({
      top: this.contactRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "logo";
    let anchor = "";
    return (
      <div className="navbar-area">
        <div className="navbar-top">
          <div className="container">
            <div className="row">
              <div className="col-md-8 text-md-left text-center">
                <ul>
                  <li>
                    <p>
                      <i className="fa fa-map-marker" /> NO.75/7, WEST OF CHORD
                      ROAD, BENGALURU-560010
                    </p>
                  </li>
                  <li>
                    <p>
                      <i className="fa fa-envelope-o" /> career@aaravedtech.com
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="topbar-right text-md-right text-center">
                  <li style={{ color: "black", fontWeight: "bold" }}>
                    <i className="fa fa-phone" style={{ fontSize: "12px" }} />{" "}
                    &nbsp; +91 8073057369
                  </li>
                  <li className="social-area">
                    <a href="https://www.facebook.com/aaravedtech">
                      <i className="fa fa-facebook" aria-hidden="true" />
                    </a>
                    <a href="https://twitter.com/aarav_pvt">
                      <i className="fa fa-twitter" aria-hidden="true" />
                    </a>
                    <a href="https://www.instagram.com/aaravedtech">
                      <i className="fa fa-instagram" aria-hidden="true" />
                    </a>
                    <a href="https://www.linkedin.com/in/aarav-ed-tech-b16b38293">
                      <i className="fa fa-linkedin" aria-hidden="true" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <nav className="navbar navbar-area-2 navbar-area navbar-expand-lg go-top">
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <button
                className="menu toggle-btn d-block d-lg-none"
                data-target="#edumint_main_menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-left" />
                <span className="icon-right" />
              </button>
            </div>
            <div
              className="logo"
              style={{
                // position: "absolute",
                // top: "15px",
                // left: "45px",
                height: "80px",
                width: "180px",
              }}
            >
              <Link to="/">
                <img
                  style={{
                    width: "120px",
                    height: "100px",
                    // border: "2px solid white",
                  }}
                  src={publicUrl + "assets/img/lo.jpeg"}
                  alt="Full stack developer course"
                />
              </Link>
            </div>
            <div className="nav-right-part nav-right-part-mobile">
              {/* <Link className="signin-btn" to="/">
                    Sign In
                  </Link> */}
              <Link
                className="btn btn-base"
                to="/sign-up"
                onClick={this.scrollToContact}
              >
                Contact
              </Link>
              <Link className="search-bar" to="#">
                <i className="fa fa-search" />
              </Link>
            </div>
            <div
              className="collapse navbar-collapse"
              id="edumint_main_menu"
              style={{ maxWidth: "2500px" }}
            >
              <ul className="navbar-nav menu-open">
                <li className="menu-item-has-children current-menu-item">
                  <Link to="/">Home</Link>
                  {/* <ul className="sub-menu">
                        <li>
                          <Link to="/">Home 01</Link>
                        </li>
                        <li>
                          <Link to="/home-v2">Home 02</Link>
                        </li>
                        <li>
                          <Link to="/home-v3">Home 03</Link>
                        </li>
                      </ul> */}
                </li>
                <li className="menu-item-has-children current-menu-item">
                  <Link to="/about">About Us</Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/course">Courses</Link>
                  {/* <ul className="sub-menu">
                        <li>
                          <Link to="/course">Course</Link>
                        </li>
                        <li>
                          <Link to="/course-details">Course Single</Link>
                        </li>
                      </ul> */}
                </li>

                {/* <li className="menu-item-has-children">
                      <Link to="#">Pages</Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li>
                          <Link to="/event">Event</Link>
                        </li>
                        <li>
                          <Link to="/event-details">Event Details</Link>
                        </li>
                        <li>
                          <Link to="/instructor">Instructor</Link>
                        </li>
                        <li>
                          <Link to="/instructor-details">
                            Instructor Details
                          </Link>
                        </li>
                        <li>
                          <Link to="/pricing">Pricing</Link>
                        </li>
                        <li>
                          <Link to="/gallery">Gallery</Link>
                        </li>
                        <li>
                          <Link to="/sign-in">Sign In</Link>
                        </li>
                        <li>
                          <Link to="/sign-up">Sign Up</Link>
                        </li>
                      </ul>
                    </li> */}

                {/* <li>
                      <Link to="/contact">About Us</Link>
                    </li> */}
                <li className="menu-item-has-children">
                  <Link to="/blog">Blog</Link>
                  {/* <ul className="sub-menu">
                        <li>
                          <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                          <Link to="/blog-details">Blog Details</Link>
                        </li>
                      </ul> */}
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div className="nav-right-part nav-right-part-desktop style-black">
              {/* <Link className="signin-btn" to="/sign-in">
                    Sign In
                  </Link> */}
              <Link className="btn btn-base" to="/contact">
                Contact
              </Link>
              {/* <Link className="search-bar" to="/">
                <i className="fa fa-search" />
              </Link> */}
            </div>
          </div>
        </nav>
      </div>
    );
  }
}


export default NavbarV2