import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class AboutV2 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    let SectionClass = this.props.sectionclass ? this.props.sectionclass : "";

    return (
      <div className={"about-area pd-top-120 go-top " + SectionClass}>
        <div className="container">
          <div className="about-area-inner">
            <div className="row">
              <div className="col-lg-6 col-md-10">
                <div
                  className="about-thumb-wrap after-shape"
                  style={{
                    backgroundImage:
                      'url("' + publicUrl + 'assets/img/about/a.jpeg")',
                  }}
                ></div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner-wrap">
                  <div className="section-title mb-0">
                    <h6 className="sub-title right-line">Why Us</h6>
                    <h2 className="title">
                      Education in continuing a proud tradition.
                    </h2>
                    <p className="content">
                      Aarav EdTech is dedicated to empowering our students with
                      the essential skills and self-assurance required to thrive
                      in the ever-evolving tech arena.
                    </p>
                    <div className="row">
                      <div className="col-sm-6">
                        <ul className="single-list-wrap">
                          <li className="single-list-inner style-check-box">
                            <i className="fa fa-check" /> Real time live project
                          </li>
                          <li className="single-list-inner style-check-box">
                            <i className="fa fa-check" />
                            &nbsp;Increased Productivity
                          </li>
                          <li className="single-list-inner style-check-box">
                            <i className="fa fa-check" /> Employee Engagement
                          </li>
                          <li className="single-list-inner style-check-box">
                            <i className="fa fa-check" /> Competitive Advantage
                          </li>
                          <li className="single-list-inner style-check-box">
                            <i className="fa fa-check" /> Leadership Development
                          </li>
                        </ul>
                      </div>
                      <div className="col-sm-6">
                        <ul className="single-list-wrap">
                          <li className="single-list-inner style-check-box">
                            <i className="fa fa-check" /> 10+ experience
                            instructor
                          </li>
                          <li className="single-list-inner style-check-box">
                            <i className="fa fa-check" /> Innovation
                          </li>
                          <li className="single-list-inner style-check-box">
                            <i className="fa fa-check" /> Adaptation to Change
                          </li>
                          <li className="single-list-inner style-check-box">
                            <i className="fa fa-check" />
                            Customer Satisfaction
                          </li>
                          <li className="single-list-inner style-check-box">
                            <i className="fa fa-check" /> Ethical & Values
                            Alignment
                          </li>
                        </ul>
                      </div>
                    </div>
                    <Link className="btn btn-border-black " to="/about">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutV2;
