import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loggedIn: false, //  loggedIn state to track successful login
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  // handleSubmit = (e) => {
  //   e.preventDefault();
  //   const { email, password } = this.state;

  //   if (!email || !password) {
  //     alert("Please fill in all fields");
  //   } else {
  //     axios
  //       .post("https://aarav-ed-tech.onrender.com/auth/login", {
  //         email,
  //         password,
  //       })
  //       .then((res) => {
  //         alert(res.data.message);
  //         console.log(res.data);

  //         // Store user data in localStorage
  //         localStorage.setItem(
  //           "user",
  //           JSON.stringify({
  //             token: res.data.token,
  //             role: res.data.role,
  //             userId: res.data.userId,
  //           })
  //         );
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    if (!email || !password) {
      alert("Please fill in all fields");
    } else {
      axios
        .post("https://aarav-ed-tech.onrender.com/auth/login", {
          email,
          password,
        })
        .then((res) => {
          alert(res.data.message);
          console.log(res.data);

          // Store user data in localStorage
          localStorage.setItem(
            "user",
            JSON.stringify({
              token: res.data.token,
              role: res.data.role,
              userId: res.data.userId,
            })
          );

          // Update the state to indicate successful login
          this.setState({
            loggedIn: true,
          });
        })
        .catch((err) => {
          console.log(err);
          alert("Login failed. Please check your credentials.");
        });
    }
  };
  render() {
    // If loggedIn state is true, redirect to a different route
    if (this.state.loggedIn) {
      return <Redirect to="/" />;
    }
    return (
      <div className="signin-page-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7">
              <form className="signin-inner" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="single-input-inner style-bg-border">
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="single-input-inner style-bg-border">
                      <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-4">
                    <button className="btn btn-base w-100" type="submit">
                      Sign In
                    </button>
                  </div>
                  <div className="col-12">
                    <Link to="#">Forgotten Your Password</Link>
                    <Link className="ml-2" to="signup.html">
                      <strong>Signup</strong>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Signin;
