import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Footer_v1 from "../global-components/footer";
import NavbarV2 from "../global-components/navbar-v2";

class CourseDetails extends Component {
  state = {
    item: null,
    image: null,
  };

  componentDidMount() {
    const id = this.props.match.params.id;

    // Replace this with your API endpoint
    const apiUrl = `https://course-9rh3.onrender.com/course/${id}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Filter the data to find the item with the matching ID
        const filteredItem = data; // Replace with your filtering logic
        this.setState({ item: filteredItem, image: data.img });
      })

      .catch((error) => {
        // Handle any errors
        console.error("Error:", error);
      });
  }

  render() {
    const { item } = this.state;
    const { image } = this.state;
    let publicUrl = process.env.PUBLIC_URL + "/";

    if (!item) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        <NavbarV2 />
        <div
          className="course-single-area pd-top-120 pd-bottom-90"
          style={{ paddingTop: "200px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="course-course-detaila-inner">
                  <div className="details-inner">
                    <div className="emt-user">
                      <span className="u-thumb">
                        <img
                          src={publicUrl + "assets/img/author/1.png"}
                          alt="img"
                        />
                      </span>
                      <span className="align-self-center">
                        {item.instructor_name}
                      </span>
                    </div>
                    <h3 className="title">{item.course_name}</h3>
                  </div>
                  <div className="thumb">
                    {/* <img src={publicUrl + "assets/img/course/a.png"} alt="img" /> */}
                    <img src={image} alt="img" />
                  </div>
                  <div className="course-details-nav-tab text-center">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="tab1-tab"
                          data-toggle="tab"
                          href="#tab1"
                          role="tab"
                          aria-controls="tab1"
                          aria-selected="true"
                        >
                          Description
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="tab2-tab"
                          data-toggle="tab"
                          href="#tab2"
                          role="tab"
                          aria-controls="tab2"
                          aria-selected="false"
                        >
                          FAQ
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#tab3"
                          role="tab"
                          id="tab3-tab"
                          aria-controls="tab3"
                          aria-selected="false"
                          aria-disabled="true"
                        >
                          Curriculum
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="tab4-tab"
                          data-toggle="tab"
                          href="#tab4"
                          role="tab"
                          aria-controls="tab4"
                          aria-selected="false"
                        >
                          Review
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="tab1"
                      role="tabpanel"
                      aria-labelledby="tab1-tab"
                    >
                      <div className="course-details-content">
                        {" "}
                        {parse(item.course_description)}
                        <div className="row pt-4">
                          <div className="col-sm-6">
                            <ul className="single-list-wrap" id="myList">
                              {item.course_description_points
                                ?.slice(0, 3)
                                .map((point, index) => (
                                  <li
                                    key={index}
                                    className="single-list-inner style-check-box"
                                  >
                                    <i className="fa fa-check" /> {point}
                                  </li>
                                ))}
                            </ul>
                          </div>

                          <div className="col-sm-6 mt-3 mt-sm-0">
                            <ul className="single-list-wrap">
                              {item.course_description_points
                                ?.slice(3, 6)
                                .map((point, index) => (
                                  <li
                                    key={index}
                                    className="single-list-inner style-check-box"
                                  >
                                    <i className="fa fa-check" /> {point}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <div
                            className="col-sm-6"
                            style={{
                              marginTop: "13px",
                            }}
                          >
                            <ul className="single-list-wrap">
                              {item.course_description_points
                                ?.slice(6, 9)
                                .map((point, index) => (
                                  <li
                                    key={index}
                                    className="single-list-inner style-check-box"
                                  >
                                    <i className="fa fa-check" /> {point}
                                  </li>
                                ))}
                            </ul>
                          </div>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tab2"
                      role="tabpanel"
                      aria-labelledby="tab2-tab"
                    >
                      <div className="course-details-content">
                        {" "}
                        <div id="accordion" className="accordion-area amt-4">
                          <div className="card single-faq-inner style-no-border">
                            <div className="card-header" id="ff-one">
                              <h5 className="mb-0">
                                <button
                                  className="btn-link"
                                  data-toggle="collapse"
                                  data-target="#f-one"
                                  aria-expanded="true"
                                  aria-controls="f-one"
                                >
                                  {item.faq.map((faq) => (
                                    <div key={faq._id}>
                                      <h4 style={{ fontSize: "18px" }}>
                                        {faq.question}
                                      </h4>
                                      <p
                                        style={{
                                          fontSize: "15px",

                                          padding: "10px",
                                        }}
                                      >
                                        {faq.answer}
                                      </p>
                                    </div>
                                  ))}
                                  {/* <i className="fa fa-eye" /> */}
                                </button>
                              </h5>
                            </div>
                            <div
                              id="f-one"
                              className="show collapse"
                              aria-labelledby="ff-one"
                              data-parent="#accordion"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tab3"
                      role="tabpanel"
                      aria-labelledby="tab3-tab"
                    >
                      <div className="course-details-content">
                        {/* <h4 className="title">Overview</h4> */}
                        <div id="accordion-1" className="accordion-area mt-4">
                          {item.curriculum.map((curr, index) => (
                            <div
                              className="card single-faq-inner style-header-bg"
                              key={curr._id}
                            >
                              <div
                                className="card-header"
                                id={`ff-${curr._id}`}
                              >
                                <h5 className="mb-0">
                                  <button
                                    className="btn-link"
                                    data-toggle="collapse"
                                    data-target={`#f-${curr._id}`}
                                    aria-expanded="true"
                                    aria-controls={`f-${curr._id}`}
                                  >
                                    <div>
                                      <h4 style={{ fontSize: "18px" }}>
                                        Module{index + 1}
                                      </h4>
                                      {/* <br /> */}
                                      {index + 1} &nbsp;
                                      {curr.module}
                                      <ul
                                        style={{
                                          fontSize: "15px",
                                          padding: "10px",
                                        }}
                                      >
                                        {curr.topics.map((topic, index) => (
                                          <li key={index}>{topic}</li>
                                        ))}
                                      </ul>
                                    </div>
                                    {/* <i className="fa fa-eye" /> */}
                                  </button>
                                </h5>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tab4"
                      role="tabpanel"
                      aria-labelledby="tab4-tab"
                    >
                      <div className="ratings-list-inner mb-4">
                        <div className="row">
                          <div className="col-md-4 align-self-center text-center">
                            <div className="total-avarage-rating">
                              <h2>5.0</h2>
                              <div className="rating-inner">
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                                <i className="fa fa-star" />
                              </div>
                              <p>Rated 5 out of 3 Ratings</p>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <ul>
                              <li>
                                <a href="#">
                                  <span className="counter-label">
                                    <i className="fa fa-star" />5
                                  </span>
                                  <span className="progress-bar-inner">
                                    <span className="progress">
                                      <span
                                        className="progress-bar"
                                        role="progressbar"
                                        aria-valuenow={100}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        style={{ width: "100%" }}
                                      />
                                    </span>
                                  </span>
                                  <span className="counter-count">100%</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <span className="counter-label">
                                    <i className="fa fa-star" />4
                                  </span>
                                  <span className="progress-bar-inner">
                                    <span className="progress">
                                      <span
                                        className="progress-bar"
                                        role="progressbar"
                                        aria-valuenow={80}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        style={{ width: "0%" }}
                                      />
                                    </span>
                                  </span>
                                  <span className="counter-count">0%</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <span className="counter-label">
                                    <i className="fa fa-star" />3
                                  </span>
                                  <span className="progress-bar-inner">
                                    <span className="progress">
                                      <span
                                        className="progress-bar"
                                        role="progressbar"
                                        aria-valuenow={0}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        style={{ width: "0%" }}
                                      />
                                    </span>
                                  </span>
                                  <span className="counter-count">0%</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <span className="counter-label">
                                    <i className="fa fa-star" />2
                                  </span>
                                  <span className="progress-bar-inner">
                                    <span className="progress">
                                      <span
                                        className="progress-bar"
                                        role="progressbar"
                                        aria-valuenow={0}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        style={{ width: "0%" }}
                                      />
                                    </span>
                                  </span>
                                  <span className="counter-count">0%</span>
                                </a>
                              </li>
                              <li>
                                <a href="#">
                                  <span className="counter-label">
                                    <i className="fa fa-star" />1
                                  </span>
                                  <span className="progress-bar-inner">
                                    <span className="progress">
                                      <span
                                        className="progress-bar"
                                        role="progressbar"
                                        aria-valuenow={0}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        style={{ width: "0%" }}
                                      />
                                    </span>
                                  </span>
                                  <span className="counter-count">0%</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="td-sidebar">
                  <div className="widget widget_feature">
                    <h4 className="widget-title">Course Features</h4>
                    <ul>
                      <li>
                        <i className="fa fa-user" />
                        <span>Enrolled :</span> {item.enrolled_students}{" "}
                        Students
                      </li>

                      <li>
                        <i className="fa fa-clipboard" />
                        <span>Lectures :</span> {item.number_of_lectures}
                      </li>
                      <li>
                        <i className="fa fa-clock-o" />
                        <span>Duration :</span> {item.duration}
                      </li>

                      {/* <li>
                      <i className="fa fa-clone" />
                      <span>Categories:</span> {item.course_description_points}
                    </li> */}
                      {/* <li>
                      <i className="fa fa-tags" />
                      <span>Tags:</span>{item.enrolled_students}
                    </li> */}
                      <li>
                        <i className="fa fa-clipboard" />
                        <span>Instructor:</span>
                        {item.instructor_name}
                      </li>
                    </ul>
                    <div className="price-wrap text-center">
                      <h5>
                        Price: <span> ₹ {item.price}</span>
                      </h5>
                      <Link className="btn btn-base btn-radius" to="/contact">
                        ENROLL COURSE
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer_v1 />
      </div>
    );
  }
}

export default CourseDetails