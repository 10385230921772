import React from "react";
import Navbar from "./global-components/navbar-v2";
import PageHeader from "./global-components/page-header";
import ContactPage from "./section-components/contact-page";
import Footer from "./global-components/footer";
//ContactUs.jsx
import useAnalyticsEventTracker from './useAnalyticsEventTracker';

const Contact = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Contact" useAnalyticsEventTracker={useAnalyticsEventTracker} />
      <ContactPage />
      <Footer />
    </div>
  );
};

export default Contact;
