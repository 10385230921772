import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { navigate } from "react-router-dom";

const obj = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  // password: "",
};

class SignUP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: obj,
      isLoading: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      form: { ...this.state.form, [name]: value },
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.isLoading) return;

    this.setState({ isLoading: true });
    console.log(this.state.form, "data");

    try {
      const res = await axios.post(
        "https://aarav-ed-tech.onrender.com/auth/signup",
        this.state.form
      );
      alert(res.data.message);
      console.log(res.data.message);

      this.props.history.push("/");
    } catch (err) {
      console.error(err);
      alert("An error occurred while signing up.");
    } finally {
      // Reset loading state
      this.setState({ isLoading: false });
    }
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="signup-page-area pd-top-120 pd-bottom-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7">
              <form className="signin-inner" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="single-input-inner style-bg-border">
                      <input
                        type="text"
                        placeholder="First Name"
                        name="first_name"
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="single-input-inner style-bg-border">
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="last_name"
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="single-input-inner style-bg-border">
                      <input
                        type="text"
                        placeholder="Phone Number"
                        name="phone"
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="single-input-inner style-bg-border">
                      <input
                        type="text"
                        placeholder="Email"
                        name="email"
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="single-input-inner style-bg-border">
                      <input
                        type="text"
                        placeholder="Password"
                        name="password"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div> */}

                  <div className="col-12 mb-4">
                    <button
                      className="btn btn-base w-100"
                      disabled={this.state.isLoading}
                    >
                      {this.state.isLoading
                        ? "Submitting..."
                        : "Create Account"}
                    </button>
                  </div>

                  <div className="col-12">
                    <span>By creating an account</span>
                    <Link to="/" className="ml-2">
                      <strong>Sign in</strong>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignUP); // Wrap the component with withRouter for navigation
