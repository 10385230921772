import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class FeaturedCourse extends Component {
  state = {
    courseData: [],
  };

  componentDidMount() {
    const apiUrl = "https://course-9rh3.onrender.com/course";

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ courseData: data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="course-area pd-top-110 pd-bottom-90 go-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="section-title text-center">
                <h6 className="sub-title double-line">OUR COURSES</h6>
                <h2 className="title">Top Featured Courses</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.courseData.map((data, i) => (
              <div className="col-lg-4 col-md-6" key={i}>
                <div className="single-course-inner style-two">
                  <div className="emt-thumb-icon">
                    <img
                      src={publicUrl + "assets/img/icon/6.png"}
                      alt="Full stack developer course"
                    />
                  </div>
                  <div className="thumb">
                    <img
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                        height: "230px",
                        width: "500px",
                      }}
                      src={data.img}
                      alt="Ui/Ux design courses"
                    />
                  </div>
                  <div className="details">
                    <div className="emt-course-meta border-0">
                      <div className="row">
                        <div className="col-10">
                          <h6>
                            <Link to={`/course-details/${data._id}`}>
                              {data.course_name}
                            </Link>
                          </h6>
                        </div>
                        <div className="col-2 text-right">
                          <Link
                            className="arrow-right"
                            to={`/course-details/${data._id}`}
                          >
                            <img
                              src={publicUrl + "assets/img/icon/5.png"}
                              alt="Devops training online courses"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default FeaturedCourse;