/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { navigate } from "react-router-dom";

const obj = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  // password: "",
};

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: obj,
      isLoading: false,
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      form: { ...this.state.form, [name]: value },
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.isLoading) return;

    this.setState({ isLoading: true });
    console.log(this.state.form, "data");

    try {
      const res = await axios.post(
        "https://aarav-ed-tech.onrender.com/auth/signup",
        this.state.form
      );
      alert("Your form submiited sucessfully");
      console.log(res.data.message);

      this.props.history.push("/");
    } catch (err) {
      console.error(err);
      alert("An error occurred while signing up.");
    } finally {
      // Reset loading state
      this.setState({ isLoading: false });
    }
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div>
        <div className="contact-list pd-top-120 pd-bottom-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4">
                <div className="contact-list-inner">
                  <div className="media">
                    <div className="media-left">
                      <img
                        src={publicUrl + "assets/img/icon/17.png"}
                        alt="img"
                      />
                    </div>
                    <div className="media-body align-self-center">
                      <h5>Our Phone</h5>
                      <p>+91 8073057369</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contact-list-inner">
                  <div className="media">
                    <div className="media-left">
                      <img
                        src={publicUrl + "assets/img/icon/18.png"}
                        alt="img"
                      />
                    </div>
                    <div className="media-body align-self-center">
                      <h5>Our Email</h5>
                      <p>career@aaravedtech.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="contact-list-inner">
                  <div className="media">
                    <div className="media-left">
                      <img
                        src={publicUrl + "assets/img/icon/16.png"}
                        alt="img"
                      />
                    </div>
                    <div className="media-body align-self-center">
                      {/* <h5>Our Address</h5>
                      <p>2 St, Loskia, amukara.</p> */}
                      <p style={{ fontSize: "14px" }}>
                        NO.75/7, WEST OF CHORD ROAD, NEXT TO BANGLORE ONE,
                        R-BLOCK, RAJAJINAGAR,
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* counter area start */}
        <div className="counter-area pd-bottom-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="section-title mb-0">
                  <h6 className="sub-title right-line">Get in touch</h6>
                  <h2 className="title">Write Us a Message</h2>
                  <p className="content pb-3">
                    🚀 Are you ready to shape the future? Join us on a journey
                    where lines of code become the building blocks of
                    innovation. Let's write the future together, one line at a
                    time. 💻🌟{" "}
                  </p>
                  <ul className="social-media style-base pt-3">
                    <li>
                      <a href="https://www.facebook.com/aaravedtech">
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/aarav_pvt">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/aaravedtech/">
                        <i className="fa fa-instagram" aria-hidden="true" />
                      </a>
                    </li>
                    {/* <li>
                      <a href="#">
                        <i className="fa fa-pinterest" aria-hidden="true" />
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.linkedin.com/in/aarav-ed-tech-b16b38293">
                        <i className="fa fa-linkedin" aria-hidden="true" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 mt-5 mt-lg-0">
                <form
                  className="contact-form-inner  mt-5 mt-md-0"
                  onSubmit={this.handleSubmit}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="single-input-inner style-bg-border">
                        <input
                          type="text"
                          placeholder="First Name"
                          name="first_name"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="single-input-inner style-bg-border">
                        <input
                          type="text"
                          placeholder="Last Name"
                          name="last_name"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="single-input-inner style-bg-border">
                        <input
                          type="text"
                          placeholder="Phone Number"
                          name="phone"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="single-input-inner style-bg-border">
                        <input
                          type="text"
                          placeholder="Email"
                          name="email"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-input-inner style-bg-border">
                        <textarea
                          type="text"
                          name="password"
                          onChange={this.handleChange}
                          placeholder="Ask any doubt..."
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-base"
                        disabled={this.state.isLoading}
                      >
                        {this.state.isLoading
                          ? "Submitting..."
                          : "Send Message"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* counter area end */}
        {/* contact area start */}
        {/* <div className="contact-g-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d29208.601361499546!2d90.3598076!3d23.7803374!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1589109092857!5m2!1sen!2sbd" />
        </div> */}
      </div>
    );
  }
}

export default withRouter(ContactPage);
