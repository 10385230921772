// import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';
// import imageSlider from "../imageSlider";

// // Update the path to match your file structure

// class BannerV2 extends Component {
//   render() {
//     let publicUrl = process.env.PUBLIC_URL + "/";

//     return (
//       <div className="banner-area banner-area-3 bg-black go-top">
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-lg-5 col-md-10">
//               <div className="thumb b-animate-thumb">
//                 <imageSlider />
//               </div>
//             </div>
//             <div className="col-lg-7 align-self-center mt-5 mt-lg-0">
//               <div className="banner-inner style-white text-center text-lg-left">
//                 <h6 className="b-animate-1 banner-btn">PLACE TO GROW</h6>
//                 <h1 className="b-animate-2 title">
//                   We are creating leaders for tomorrow
//                 </h1>
//                 <Link
//                   className="btn btn-base b-animate-3 mr-sm-3 mr-2"
//                   to="/Contact"
//                 >
//                   Get A Quote
//                 </Link>
//                 <Link className="btn btn-border-white b-animate-3" to="/blog">
//                   Read More
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default BannerV2;

// import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import parse from "html-react-parser";

// class BannerV3 extends Component {
//   render() {
//     let publicUrl = process.env.PUBLIC_URL + "/";

//     return (
//       <div className="banner-area banner-area-3 bg-black go-top">
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-lg-5 col-md-10">
//               <div className="thumb b-animate-thumb">
//                 <img src={publicUrl + "assets/img/banner/3.png"} alt="img" />
//               </div>
//             </div>
//             <div className="col-lg-7 align-self-center mt-5 mt-lg-0">
//               <div className="banner-inner style-white text-center text-lg-left">
//                 <h6 className="b-animate-1 banner-btn">PLACE TO GROW</h6>
//                 <h1 className="b-animate-2 title">
//                   We creating leaders for tomorrow
//                 </h1>
//                 <Link
//                   className="btn btn-base b-animate-3 mr-sm-3 mr-2"
//                   to="/contact"
//                 >
//                   Get A Quote
//                 </Link>
//                 <Link className="btn btn-border-white b-animate-3" to="/blog">
//                   Read More
//                 </Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default BannerV3;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

class BannerV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
    };
  }

  componentDidMount() {
    this.sliderInterval = setInterval(() => {
      this.next();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.sliderInterval);
  }

  next = () => {
    this.slider.slickNext();
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    const settings = {
      // dots: true,
      // infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: (current) => this.setState({ currentSlide: current }),
    };

    return (
      <div className="banner-area banner-area-3 bg-black go-top">
        <div
          className="container"
          style={
            {
              // border: "2px solid red",
              // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              // boxShadow:
              //   " 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%)",
            }
          }
        >
          <Slider ref={(slider) => (this.slider = slider)} {...settings}>
            <div className="banner-slide">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-10">
                  <div className="thumb b-animate-thumb">
                    <img
                      src={
                        publicUrl +
                        `assets/img/banner/${
                          (this.state.currentSlide % 2) + 3
                        }.png`
                      }
                      alt="Full stack developer course"
                    />
                  </div>
                </div>
                <div className="col-lg-7 align-self-center mt-5 mt-lg-0">
                  <div className="banner-inner style-white text-center text-lg-left">
                    <h6 className="b-animate-1 banner-btn">PLACE TO GROW</h6>
                    <h1 className="b-animate-2 title">
                      We are creating leaders for tomorrow
                    </h1>
                    <Link
                      className="btn btn-base b-animate-3 mr-sm-3 mr-2"
                      to="/contact"
                    >
                      Get A Quote
                    </Link>
                    <Link
                      className="btn btn-border-white b-animate-3"
                      to="/blog"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="banner-slide">
              {/* Add your second slide content here */}
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-10">
                  <div className="thumb b-animate-thumb">
                    {/* <img
                      src={
                        publicUrl +
                        `assets/img/banner/${
                          (this.state.currentSlide % 2) + 3
                        }.png`
                      }
                      alt="img"s
                    /> */}
                    <img
                      src={publicUrl + "assets/img/banner/1.png"}
                      alt="Top devop courses online"
                    />
                  </div>
                </div>
                <div className="col-lg-7 align-self-center mt-5 mt-lg-0">
                  <div className="banner-inner style-white text-center text-lg-left">
                    <h6 className="b-animate-1 banner-btn">PLACE TO GROW</h6>
                    <h1 className="b-animate-2 title">
                      Empowering the leaders of tomorrow.
                    </h1>
                    <Link
                      className="btn btn-base b-animate-3 mr-sm-3 mr-2"
                      to="/contact"
                    >
                      Get A Quote
                    </Link>
                    <Link
                      className="btn btn-border-white b-animate-3"
                      to="/blog"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

export default BannerV2;
