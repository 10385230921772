import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './sidebar';
class Blog extends Component {


  render() {
    let publicUrl = process.env.PUBLIC_URL+'/'

    return (
      <div className="blog-area pd-top-120 pd-bottom-120 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-blog-inner style-border">
                <div className="thumb">
                  <img src={publicUrl + "assets/img/blog/b1.png"} alt="img" />
                </div>
                <div className="details">
                  <ul className="blog-meta">
                    <li>
                      <i className="fa fa-user" /> BY MEGHA
                    </li>
                    <li>
                      <i className="fa fa-calendar-check-o" /> 28 JANUARY, 2020
                    </li>
                  </ul>
                  <h3 className="title">
                    <Link to="/blog">
                      Unlocking Your Potential: Arrav Ed Tech's Online IT
                      Development Classes
                    </Link>
                  </h3>
                  <p>
                    In today's digitally driven world, a career in information
                    technology can open doors to a multitude of opportunities.
                    As technology continues to evolve at breakneck speed,
                    acquiring the right skills and knowledge is essential for
                    staying competitive in the job market. This is where Arrav
                    Ed Tech's online IT development classes come into play,
                    offering a transformative educational experience that
                    empowers individuals to thrive in the tech industry.
                  </p>
                  {/* <Link className="read-more-text" to="/blog">
                    READ MORE <i className="fa fa-angle-right" />
                  </Link> */}
                </div>
              </div>
              <div className="single-blog-inner style-border">
                <div className="thumb">
                  <img src={publicUrl + "assets/img/blog/b2.png"} alt="img" />
                </div>
                <div className="details">
                  <ul className="blog-meta">
                    <li>
                      <i className="fa fa-user" /> BY VINAY
                    </li>
                    <li>
                      <i className="fa fa-calendar-check-o" /> 28 JANUARY, 2020
                    </li>
                  </ul>
                  <h3 className="title">
                    <Link to="/blog">Flexible Learning for Modern Life</Link>
                  </h3>
                  <p>
                    One of the standout features of Arrav Ed Tech's IT
                    development classes is their flexibility. These courses are
                    designed with the modern learner in mind, understanding that
                    many students have busy schedules and other commitments.
                    Whether you're a working professional seeking to upskill or
                    someone looking to transition into the tech industry, the
                    flexibility of online learning makes it accessible to
                    everyone.
                  </p>
                  {/* <Link className="read-more-text" to="/blog">
                    READ MORE <i className="fa fa-angle-right" />
                  </Link> */}
                </div>
              </div>
              <div className="single-blog-inner style-border">
                <div className="thumb">
                  <img src={publicUrl + "assets/img/blog/b3.png"} alt="img" />
                </div>
                <div className="details">
                  <ul className="blog-meta">
                    <li>
                      <i className="fa fa-user" /> BY SUJEET
                    </li>
                    <li>
                      <i className="fa fa-calendar-check-o" /> 28 JANUARY, 2020
                    </li>
                  </ul>
                  <h3 className="title">
                    <Link to="/blog">Career Transformation</Link>
                  </h3>
                  <p>
                    Arrav Ed Tech is not just about education; it's about
                    transformation. Many students who have completed their IT
                    development courses at Arrav Ed Tech have gone on to secure
                    rewarding careers in the tech industry. The quality
                    education they receive equips them with the knowledge and
                    skills necessary to thrive in a competitive job market.
                  </p>
                  {/* <Link className="read-more-text" to="/blog">
                    READ MORE <i className="fa fa-angle-right" />
                  </Link> */}
                </div>
              </div>
              {/* <nav className="td-page-navigation">
                <ul className="pagination">
                  <li className="pagination-arrow">
                    <Link to="#">
                      <i className="fa fa-angle-double-left" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">1</Link>
                  </li>
                  <li>
                    <Link className="active" to="#">
                      2
                    </Link>
                  </li>
                  <li>
                    <Link to="#">...</Link>
                  </li>
                  <li>
                    <Link to="#">3</Link>
                  </li>
                  <li className="pagination-arrow">
                    <Link to="#">
                      <i className="fa fa-angle-double-right" />
                    </Link>
                  </li>
                </ul>
              </nav> */}
            </div>
            <Sidebar />
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
